<template>
    <v-container fluid>
        <div class="mb-3">
            <v-select v-model="period" :items="periodsOpt" outlined solo flat hide-details dense 
                @change="updateDashboard" style="width: 300px;"></v-select>
        </div>

        <v-row no-gutters>
            <v-col cols="8">
                <v-card flat class="mr-3" :loading="loading">
                    <v-card-text>
                        <highcharts :options="chartOptions" />
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="4">
                <v-card flat class="mb-3" :loading="loading">
                    <v-card-title>Pickups</v-card-title>
                    <v-card-subtitle>Orders that should be picked up by customers within this period</v-card-subtitle>
                    <v-card-text>
                        <div class="display-3">{{ Number( pickups ).toLocaleString() }}</div>
                    </v-card-text>
                </v-card>

                <v-card flat class="mb-3" :loading="loading">
                    <v-card-title>Returns</v-card-title>
                    <v-card-subtitle>Orders that should be returned within this period</v-card-subtitle>
                    <v-card-text>
                        <div class="display-3">{{ Number( returns ).toLocaleString() }}</div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { API } from '@/inc/api';
import { _st } from '@/softech';
import { Chart } from 'highcharts-vue';
import moment from 'moment';

export default {
    data: () => ({
        loading     : false,
        period      : '7days',

        stores      : null,
        pickups     : 0,
        returns     : 0,
        storesAvg   : 0,
        chartsLib   : null, 

        periodsOpt  : [
            { text: 'Last 7 days', value: '7days' },
            { text: 'Last 30 days', value: '30days' },
            { text: 'Last 60 days', value: '60days' },
            { text: 'This month', value: 'thismonth' },
            { text: 'Last month', value: 'lastmonth' },
            { text: 'This year', value: 'thisyear' },
            { text: 'Last year', value: 'lastyear' },
        ]
    }),
    computed: {
        storePerformanceData() {
            let data = [
                ['Store', 'Order Qty', 'Revenue', 'Avg Revenue', { role: 'style' }, { role: 'annotation' }]
            ];

            if( this.stores == null )
                return data;

            let i = 0;
            this.stores.forEach(s => {
                let color = 'color: #FF9800';
                if ( i <= 2 )
                    color = 'color: #4CAF50';
                else if ( i >= 5 )
                    color = 'color: #F44336';

                data.push([ s.store, parseInt( s.qty ), parseFloat( s.revenue ), parseFloat( s.avgRevenue ), color, _st.toMoney( s.revenue ) ]);
                i++;
            });

            return data;
        },
        chartOptions() {
            if( this.stores == null )
                return {};

            let ix = this.periodsOpt.findIndex(p => p.value == this.period);

            return {
                chart: { type: 'spline' },
                title: {
                  text: 'Store Performance',
                  align: 'left',
                },
                subtitle: {
                  text: this.periodsOpt[ix].text,
                  align: 'left',
                },
                xAxis: { categories: _st.extractProp( this.stores, 'store' ) },
                yAxis: [{
                    title: { text: 'Total Orders' },
                    plotLines: [{
                        color: 'orange',
                        value: this.storesAvg,
                        width: 1,
                        zIndex: 4,
                        dashStyle: 'dash',
                        label: 'Order Avg Revenue',
                    }]
                },{
                    labels: { format: '${value}' },
                    title: { text: 'Revenue ($)' },
                    opposite: true
                }],
                legend: {
                  layout: 'vertical',
                  align: 'right',
                  verticalAlign: 'middle',
                  borderWidth: 0
                },
                series: [{
                    name: 'Orders',
                    type: 'column',
                    data: _st.extractProp( this.stores, 'qty' ),            
                },{
                    name: 'Revenue',
                    type: 'spline',
                    data: _st.extractProp( this.stores, 'revenue' ),
                    yAxis: 1,
                    tooltip: { valuePrefix: '$ ' }
                },{
                    name: 'Avg Revenue',
                    type: 'spline',
                    data: _st.extractProp( this.stores, 'avgRevenue' ),
                    yAxis: 0,
                    tooltip: { valuePrefix: '$ ' }
                }],
                responsive: {
                    rules: [{
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom',
                                floating: true,
                                enabled: false
                            }
                        }
                    }]
                }
            };
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let api = new API();

            try {
                let from = null;
                let to = null;

                switch( this.period ) {
                    case '7days':
                        from = moment().subtract(7, 'day').format('YYYY-MM-DD');
                        to = moment().format('YYYY-MM-DD');
                        break;
                    case '30days':
                        from = moment().subtract(30, 'day').format('YYYY-MM-DD');
                        to = moment().format('YYYY-MM-DD');
                        break;
                    case '60days':
                        from = moment().subtract(60, 'day').format('YYYY-MM-DD');
                        to = moment().format('YYYY-MM-DD');
                        break;
                    case 'thismonth':
                        from = moment().startOf('month').format('YYYY-MM-DD');
                        to = moment().endOf('month').format('YYYY-MM-DD');
                        break;
                    case 'lastmonth':
                        from = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
                        to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
                        break;
                    case 'thisyear':
                        from = moment().startOf('year').format('YYYY-MM-DD');
                        to = moment().endOf('year').format('YYYY-MM-DD');
                        break;
                    case 'lastyear':
                        from = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
                        to = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
                        break;
                }

                this.loading = true;
                let res = await api.get(`/admin/dashboard/${from}/${to}`);
                this.loading = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                let vals = [];
                let orders = 0;
                let revenue = 0;
                res.data.data.stores.forEach(v => {
                    vals.push({
                        id: v.id,
                        store: v.store,
                        qty: parseInt( v.qty ),
                        revenue: parseFloat( v.revenue ),
                        avgRevenue: _st.toFixedNumber( parseFloat( v.avgRevenue ), 2 )
                    });

                    orders += parseInt( v.qty );
                    revenue += parseFloat( v.revenue );
                })

                this.stores = vals;
                this.storesAvg = revenue / orders;
                this.pickups = res.data.data.pickups;
                this.returns = res.data.data.returns;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        updateDashboard() {
            this.init();
        }
    },
    components: {
        highcharts: Chart
    }
}
</script>

<style lang="scss" scoped>

</style>